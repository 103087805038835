import http from '~/utils/http'


export class CollectApi {
  // @POST('/jav/collect/collectDelete')
  // Future<bool> collectDelete(@Body() IdsParam param);
  static collectDelete(params: IdsParam, opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/collect/collectDelete',
      body: params
    })
  }

  // @POST('/jav/collect/collectList')
  // Future<CollectPageResponse> collectList(@Body() JavCollectRecordParam param);
  static collectList(params: JavCollectRecordParam, opt?: HttpOptions) {
    return http.post<CollectPageResponse>({
      ...opt,
      url: '/jav/collect/collectList',
      body: params
    })
  }
}
