<script setup lang="ts">
import empty_dir from '~/assets/image/empty/empty_dir@2x.png'

import { CollectApi } from '~/net/apis/collect_api'

const onClickLeft = () => history.back()
const checked = ref([])
const checked1 = ref(false)
const checkboxGroup = ref()
const checkboxRefs = ref<any[]>([])
const showLoading = ref(false)
const pullRefreshListRef = ref(null)
function checkAll() {
  if (checked1.value) {
    checkboxGroup.value.toggleAll(true)
  } else {
    checkboxGroup.value.toggleAll()
  }
}
const collected = ref(0)
const canCollected = ref(0)
const edit = ref(false)

function choose(index: number) {
  console.log('checkboxRefs', index)
  checkboxRefs.value[index].toggle()
  if (checked.value.length === collected.value) {
    checked1.value = true
  } else {
    checked1.value = false
  }
}
const actions = [
  { name: '最近收藏', value: 'desc' },
  { name: '最久收藏', value: 'asc' }
]
const show = ref(false)
const type = ref('最近收藏')
const orderType = ref('desc')
function onSelect(item: any) {
  show.value = false
  type.value = item.name
  orderType.value = item.value
}
const actions1 = [{ name: '确认删除' }]
const show1 = ref(false)
async function onSelect1() {
  showLoading.value = true
  const ids = checked.value.map((item) => {
    return { id: `${item}` }
  })
  await CollectApi.collectDelete({ ids }).catch(() => {
    show.value = false
    showLoading.value = false
  })
  showLoading.value = false
  pullRefreshListRef.value?.onRefresh()
}
function handleData(data: any) {
  canCollected.value = (data.summary || {}).limitCollect
  collected.value = (data.summary || {}).totalCollect
}
</script>

<template>
  <div h-screen ct-d0d0d0>
    <van-nav-bar title="我的收藏" left-arrow @click-left="onClickLeft">
      <template #right>
        <button mr-2 ct-ffffff @click="edit = !edit">
          {{ edit ? '取消' : '编辑' }}
        </button>
      </template>
    </van-nav-bar>
    <div flex justify-between p-4 ct-e8e8e8>
      <div>我的收藏 {{ collected }}/{{ canCollected }}个影片</div>
      <div items-center justify-center @click="show = true">
        {{ type }}
        <van-icon :name="show ? 'arrow-up' : 'arrow-down'" ml-2 />
      </div>
    </div>
    <van-checkbox-group ref="checkboxGroup" v-model="checked" px-4>
      <PullRefreshListBottom
        :ref="(el: any) => (pullRefreshListRef = el)"
        wrap-class="w-full grid grid-cols-2 gap2"
        :request-api="CollectApi.collectList"
        :offset-list="20"
        :is-empty="true"
        :head-height="0"
        :request-params="{
          endTime: null,
          orderType,
          pageNum: 1,
          pageSize: 20,
          startTime: null
        }"
        @handle-data="handleData"
      >
        <template #="{ data }">
          <div
            v-for="(item, index) in data as Array<CollectionRecord>"
            :key="index"
            relative
            span="12"
            :class="{ editBox: edit }"
            @click="choose(index)"
          >
            <div class="card">
              <MediaBaseCard :item="item as CollectionRecord" />
            </div>
            <van-checkbox
              v-if="edit"
              :ref="(el: any) => (checkboxRefs[index] = el)"
              class="absolute left-6px top-90px z-2"
              :name="item.id"
              icon-size="15"
              checked-color="#FD8203"
              @click.stop
            />
          </div>
        </template>
        <template #empty>
          <div h-2xl flex flex-col items-center justify-center px-4>
            <img h-33 w-50 :src="empty_dir" />
            <div mt-3 text-xs ct-ffffff>暂时没有任何收藏</div>
          </div>
        </template>
      </PullRefreshListBottom>
    </van-checkbox-group>
    <div v-if="edit" fixed bottom-0 h-12 w-full flex items-center justify-between pl-2 pr-2 bg-242943 ct-e8e8e8>
      <van-checkbox v-model="checked1" checked-color="#FD8203" @click="checkAll"> 全选 </van-checkbox>
      <span @click="show1 = true">删除</span>
    </div>
    <LoadReflesh v-model:show="showLoading" />
    <van-action-sheet
      v-model:show="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="show = false"
      @select="onSelect"
    />
    <van-action-sheet
      v-model:show="show1"
      :actions="actions1"
      cancel-text="取消"
      close-on-click-action
      @cancel="show1 = false"
      @select="onSelect1"
    />
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
.bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
:deep(.van-checkbox__label) {
  color: #e8e8e8;
}
:deep(.van-action-sheet__item) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__cancel) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__gap) {
  background: #414660;
}
:deep(.van-action-sheet__item:hover) {
  color: #fd5f03;
}
:deep(.van-action-sheet__content) {
  background: #414660;
}
.editBox {
  .card {
    pointer-events: none;
  }
}
</style>
